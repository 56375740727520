import { useEffect, useState } from 'react';
import { mutate as globalMutate } from 'swr';

// Create a singleton object to store channels and listeners
type ChannelName = string;
type MutationKey = string;
type EventName = string;

const globalChannels: Record<ChannelName, MutationKey[]> = {};
const globalChannelEvents: Record<ChannelName, EventName[]> = {};

export const usePusher = ({
  type,
  channel,
  callback,
  url: key,
  createPusherConnection,
  listen,
}: Groupthink.UsePusherOptions) => {
  // create the pusher connection on mount - never again
  useEffect(() => {
    (async () => {
      await createPusherConnection?.();
    })();
  }, []);

  useEffect(() => {
    // if we don't have a channel, don't do anything - this is a safety check for when a page is loading and we don't
    // know the ID of the asset we want to listen to yet
    if (!channel) return;

    // not listening to this channel already?
    // if this array exists, we're already listening to this channel, even if it's empty
    const shouldRegisterChannel =
      !Array.isArray(globalChannels[channel]) || !globalChannelEvents[channel].includes(type);
    const shouldRegisterCallback = !!callback;

    if (shouldRegisterCallback || shouldRegisterChannel) {
      if (!Array.isArray(globalChannels[channel])) globalChannels[channel] = [];
      if (!Array.isArray(globalChannelEvents[channel])) globalChannelEvents[channel] = [];

      // new cache key mutatin' behavior
      // we do this whether or not we have a key because we might have keys in the future
      listen?.(
        (payload) => {
          // this is only the first callback we will have gotten, not updated when callback param updates
          // theoretically this is fine because we're not changing the callback function and it's built by useRealtime stuff
          // callback not required here and only used when we want optimistic updating
          callback?.(payload);

          // mutate all keys we're tracking this event for
          globalChannels[channel].forEach((mutationKey) => {
            globalMutate(mutationKey);
          });

          process.env.NEXT_PUBLIC_PUSHER_VERBOSE_LOGS === 'true' &&
            console.log('[usePusher] callback on channel', channel, type);
        },
        channel,
        type
      );

      process.env.NEXT_PUBLIC_PUSHER_VERBOSE_LOGS === 'true' &&
        console.log('[usePusher] listening to channel', channel, type);
    }

    // if we have a key and it's not already in the array, add it
    // this will be the list of keys we need to mutate when we get the pusher event
    if (channel && key && !globalChannels[channel].includes(key)) {
      globalChannels[channel].push(key);
    }

    if (channel && type && !globalChannelEvents[channel].includes(type)) {
      globalChannelEvents[channel].push(type);
    }

    // no cleanup for this function or the listens
    // don't know how many components are listening to this channel
  }, [channel, key]);
};

export const usePresence = ({
  channel,
  createPusherConnection,
  joinPresence,
}: Groupthink.UsePresenceOptions) => {
  const [presentUsers, setPresentUsers] = useState<Groupthink.PresenceUser[]>([]);

  // create the pusher connection on mount - never again
  useEffect(() => {
    (async () => {
      await createPusherConnection?.();
    })();
  }, []);

  useEffect(() => {
    // if we don't have a channel, don't do anything - this is a safety check for when a page is loading and we don't
    // know the ID of the asset we want to listen to yet
    if (!channel) return;
    // Join presence channel
    joinPresence?.(
      channel,
      // List users that are present in channel
      (users: Groupthink.PresenceUser[]) => setPresentUsers(users),
      // Add user as present in channel
      (user: Groupthink.PresenceUser) => setPresentUsers((prev) => [...prev, user]),
      // Remove user that is no longer present in channel
      (user: Groupthink.PresenceUser) =>
        setPresentUsers((prev) => prev.filter((u) => u.id !== user.id))
    );
    // no cleanup for this function or the listens
    // don't know how many components are listening to this channel
  }, [channel]);

  return presentUsers;
};

/**
 * This hook listens to a Pusher channel and updates a collection in SWR with the contents of the payload.
 */
export const useRealtimeCollection: Groupthink.RealtimeCollectionHandler = (
  eventName,
  channel,
  mutate,
  url,
  overrideMutationType,
  observationOnly = false,
  createPusherConnection,
  listen
) =>
  usePusher({
    type: eventName,
    channel: channel,
    url,
    // we're going to use the callback when pusher gets new data to merge in the payload from pusher.
    // we have to assume that pusher doesn't have the entire object, so we're going to merge it ourselves.
    callback: (payload) => {
      mutate(
        (itemsObject) => {
          // if itemsObject is undefined, return it
          if (!itemsObject || observationOnly) {
            return itemsObject;
          }

          // clone the entire API payload we retrieved from the server earlier so SWR knows that it's changed
          const clonedItemsObject = { ...itemsObject };

          // find the item that was updated in our pusher payload
          const touchedItem = clonedItemsObject.data.find((item) => item.id === payload.id);

          // filter out the item that was updated from the list of items
          const allOtherItems = clonedItemsObject.data.filter((item) => item.id !== payload.id);

          // derive the mutation type from the event name, unless it's specified
          const mutationType =
            overrideMutationType ??
            (eventName.endsWith('Deleted')
              ? 'delete'
              : eventName.endsWith('Created')
                ? 'create'
                : eventName.endsWith('Updated')
                  ? 'update'
                  : null);

          if (mutationType === 'delete') {
            // purge the deleted items
            const updatedItems = [...allOtherItems];

            // return the new items object without the deleted items
            return {
              ...clonedItemsObject,
              data: updatedItems,
            };
          } else {
            // merge the updated item with the payload from pusher
            const updatedItems = [...allOtherItems, { ...touchedItem, ...payload }];

            // return the new items object with the updated items
            return {
              ...clonedItemsObject,
              data: updatedItems,
            };
          }
        },
        {
          // don't revalidate SWR cache unless we're just observing
          revalidate: observationOnly,
          // this is a special SWR function that allows us to update the cache manually
          populateCache: (cache) => {
            return cache;
          },
        }
      );
    },
    createPusherConnection,
    listen,
  });

/**
 * This hook listens to a Pusher channel and updates a single resource in SWR with the contents of the payload.
 */
export const useRealtimeResource: Groupthink.RealtimeResourceHandler = (
  eventName,
  channel,
  mutate,
  url,
  createPusherConnection,
  listen
) => {
  usePusher({
    type: eventName,
    channel: channel,
    url,
    // we're going to use the callback when pusher gets new data to merge in the payload from pusher.
    // we have to assume that pusher doesn't have the entire object, so we're going to merge it ourselves.
    callback: (payload) =>
      mutate(
        (itemObject) => {
          if (!itemObject) {
            return itemObject;
          }

          // clone the entire API payload we retrieved from the server earlier so SWR knows that it's changed
          const clonedItemObject = Object.hasOwnProperty.call(itemObject, 'data')
            ? { ...itemObject.data }
            : { ...itemObject };

          // merge the updated item with the payload from pusher
          return { data: { ...clonedItemObject, ...payload } };
        },
        {
          // don't revalidate SWR cache
          revalidate: false,
          // this is a special SWR function that allows us to update the cache manually
          populateCache: (cache: any) => {
            return cache;
          },
        }
      ),
    createPusherConnection,
    listen,
  });
};

/**
 * This hook listens to a Pusher channel and requests a remote revalidation of the SWR cache.
 * It works for both folders and resources.
 */
export const useRealtimeMutate: Groupthink.RealtimeMutateHandler = (
  eventName,
  channel,
  url,
  createPusherConnection,
  listen
) =>
  usePusher({
    type: eventName,
    channel: channel,
    url,
    createPusherConnection,
    listen,
  });
