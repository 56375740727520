import useSWR, { mutate as globalMutate } from 'swr';
import { apiRequest, fetcher } from '../lib';

export const useProcessesUrl = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string,
  processId?: string
) => {
  const url =
    workspaceId && assistantId && responsibilityId
      ? `/v1/workspaces/${workspaceId}/assistants/${assistantId}/responsibilities/${responsibilityId}/processes`
      : '';

  if (url && processId) {
    return `${url}/${processId}`;
  }

  return url;
};

export const useProcessesMutate = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string
) => {
  const url = useProcessesUrl(workspaceId, assistantId, responsibilityId);
  return () => globalMutate(url);
};

export const useProcesses = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string
) => {
  const url = useProcessesUrl(workspaceId, assistantId, responsibilityId);

  const {
    data: processes,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    processes: processes?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useProcess = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string,
  processId: string
) => {
  const url = useProcessesUrl(workspaceId, assistantId, responsibilityId, processId);

  const {
    data: process,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    process: process?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const createProcess = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string,
  { setErrors, setIsCreating, onSuccess, payload }
) => {
  const mutate = useProcessesMutate(workspaceId, assistantId, responsibilityId);
  const url = useProcessesUrl(workspaceId, assistantId, responsibilityId);

  return apiRequest(url, mutate, 'POST', {
    setErrors,
    setLoading: setIsCreating,
    payload,
    onSuccess,
  });
};

export const updateProcess = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string,
  processId: string,
  { setErrors, setIsUpdating, onSuccess, payload }
) => {
  const mutate = useProcessesMutate(workspaceId, assistantId, responsibilityId);
  const url = useProcessesUrl(workspaceId, assistantId, responsibilityId, processId);

  return apiRequest(url, mutate, 'PUT', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};

export const deleteProcess = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string,
  processId: string,
  { setErrors, setIsDeleting, onSuccess }
) => {
  const mutate = useProcessesMutate(workspaceId, assistantId, responsibilityId);
  const url = useProcessesUrl(workspaceId, assistantId, responsibilityId, processId);

  return apiRequest(url, mutate, 'DELETE', {
    setErrors,
    setLoading: setIsDeleting,
    onSuccess,
  });
};
