import useSWR, { mutate as globalMutate } from 'swr';
import { apiRequest, fetcher } from '../lib';

export const selectResponsibilitiesUrl = (
  workspaceId: string,
  assistantId: string,
  responsibilityId?: string
) => {
  const url =
    workspaceId && assistantId
      ? `/v1/workspaces/${workspaceId}/assistants/${assistantId}/responsibilities`
      : '';

  if (url && responsibilityId) {
    return `${url}/${responsibilityId}`;
  }

  return url;
};

export const buildResponsibilitiesMutate = (
  workspaceId: string,
  assistantId: string,
  responsibilityId?: string
) => {
  const url = selectResponsibilitiesUrl(workspaceId, assistantId);
  const show_url =
    responsibilityId && selectResponsibilitiesUrl(workspaceId, assistantId, responsibilityId);

  return () => {
    show_url && globalMutate(show_url);
    return globalMutate(url);
  };
};

export const useResponsibilities = (workspaceId: string, assistantId: string) => {
  const url = selectResponsibilitiesUrl(workspaceId, assistantId);

  const {
    data: responsibility,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    responsibilities: responsibility?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useResponsibility = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string
) => {
  const url = selectResponsibilitiesUrl(workspaceId, assistantId, responsibilityId);

  const {
    data: responsibility,
    error,
    isLoading,
    mutate,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    responsibility: responsibility?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const createResponsibility = (
  workspaceId: string,
  assistantId: string,
  { setErrors, setIsCreating, onSuccess, payload }
) => {
  const mutate = buildResponsibilitiesMutate(workspaceId, assistantId);
  const url = selectResponsibilitiesUrl(workspaceId, assistantId);

  return apiRequest(url, mutate, 'POST', {
    setErrors,
    setLoading: setIsCreating,
    payload,
    onSuccess,
  });
};

export const updateResponsibility = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string,
  { setErrors = () => {}, setIsUpdating = () => {}, onSuccess = () => {}, payload = {} } = {}
) => {
  const mutate = buildResponsibilitiesMutate(workspaceId, assistantId, responsibilityId);
  const url = selectResponsibilitiesUrl(workspaceId, assistantId, responsibilityId);

  return apiRequest(url, mutate, 'PUT', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });
};

export const deleteResponsibility = (
  workspaceId: string,
  assistantId: string,
  responsibilityId: string,
  { setErrors, setIsDeleting, onSuccess }
) => {
  const mutate = buildResponsibilitiesMutate(workspaceId, assistantId, responsibilityId);
  const url = selectResponsibilitiesUrl(workspaceId, assistantId, responsibilityId);

  return apiRequest(url, mutate, 'DELETE', {
    setErrors,
    setLoading: setIsDeleting,
    onSuccess,
  });
};
