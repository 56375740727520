import {
  axios,
  fetcher,
  mutateInternal,
  apiRequest,
  configureAxiosBaseUrl,
  configureAuthorizationHeader,
  configureWithCredentials,
  clearAuthorizationHeader,
} from './client';

import { isBrowser } from './platform';

export {
  axios,
  fetcher,
  mutateInternal,
  apiRequest,
  configureAxiosBaseUrl,
  configureAuthorizationHeader,
  configureWithCredentials,
  clearAuthorizationHeader,
};

export { isBrowser };
