import {
  configureWithCredentials,
  useUser,
  configureAuthorizationHeader,
  clearAuthorizationHeader,
} from '@groupthinkai/groupthink';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSWRConfig } from 'swr';

interface TokenContextProps {
  token: string | null;
  setToken: (token: string | null) => void;
}

const TokenContext = createContext<TokenContextProps | undefined>(undefined);

export const TokenProvider: React.FC<{
  children: React.ReactNode;
  onTokenRetrieval?: () => void;
}> = ({ children, onTokenRetrieval = () => {} }) => {
  const [token, setTokenState] = useState<string | null>(null);
  const { mutate } = useUser('me');
  const router = useRouter();
  const { cache } = useSWRConfig();

  const clearSwrCache = () => {
    let cacheKeys = cache.keys().next();
    while (!cacheKeys.done) {
      cache.delete(cacheKeys.value);
      cacheKeys = cache.keys().next();
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (window?.electron) {
      // @ts-ignore
      const savedToken = window.electron.getToken();

      if (savedToken) {
        setTokenState(savedToken);
        configureAuthorizationHeader(savedToken);
        configureWithCredentials(false);
        mutate();
        // onTokenRetrieval();
      }

      // @ts-ignore
      window.electron.onTokenUpdated((newToken) => {
        setTokenState(newToken);
        if (newToken) {
          configureAuthorizationHeader(newToken);
          configureWithCredentials(false);
          clearSwrCache();
          mutate();
          router.push('/dashboard');
        } else {
          clearAuthorizationHeader();
          clearSwrCache();
          router.push('/signin');
        }
      });
    }
  }, []);

  const setToken = (token: string | null) => {
    // @ts-ignore
    if (window?.electron) {
      // @ts-ignore
      window.electron.sendToken(token);
    }
    setTokenState(token);
    if (token) {
      configureAuthorizationHeader(token);
    } else {
      clearAuthorizationHeader();
    }
  };

  // @ts-ignore
  return <TokenContext.Provider value={{ token, setToken }}>{children}</TokenContext.Provider>;
};

export const useToken = (): TokenContextProps => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error('useToken must be used within a TokenProvider');
  }
  return context;
};
