/// <reference path="../groupthink-js.d.ts" />
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SWRResponse } from 'swr';
import { isBrowser } from './platform';

const DEFAULTS = {
  serverURL: 'https://api.groupthink.com',
  groupthinkClientName: 'packages/groupthink-js',
  groupthinkClientType: '',
  useCredentials: false,
  throwOnError: false,
};

if (process.env.NEXT_PUBLIC_BACKEND_URL) {
  DEFAULTS.serverURL = process.env.NEXT_PUBLIC_BACKEND_URL;
}

if (process.env.EXPO_PUBLIC_BACKEND_URL) {
  DEFAULTS.serverURL = process.env.EXPO_PUBLIC_BACKEND_URL;
}

if (
  process.env.NEXT_PUBLIC_AXIOS_THROW_ON_ERROR === 'true' ||
  process.env.EXPO_PUBLIC_AXIOS_THROW_ON_ERROR === 'true'
) {
  DEFAULTS.throwOnError = true;
}

if (isBrowser()) {
  DEFAULTS.useCredentials = true;
  DEFAULTS.groupthinkClientType = 'web';
} else {
  DEFAULTS.groupthinkClientType = 'app';
}

export const axios = Axios.create({
  baseURL: DEFAULTS.serverURL,
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-Groupthink-Client': DEFAULTS.groupthinkClientName,
  },
  withCredentials: DEFAULTS.useCredentials,
  withXSRFToken: true,
});

if (isBrowser()) {
  // The X-Socket-ID header is used to authenticate the user with the Pusher API.
  // It allows us to exclude the user from receiving events for their own actions.
  axios.interceptors.request.use((config) => {
    // @ts-ignore
    if (window?.Echo?.socketId()) {
      // @ts-ignore
      config.headers['X-Socket-ID'] = window.Echo.socketId();
    }
    return config;
  });
}

if (process.env.NODE_ENV === 'development') {
  console.debug(
    'Groupthink API Client initialized with defaults:',
    JSON.stringify(DEFAULTS, null, 2)
  );
}

/**
 * Fetch data from the API.
 * Requires the client to be initialized.
 *
 * @param url
 * @returns The data returned by the API.
 */
export const fetcher = async (url: string) => {
  const res = await axios.get(url);

  if (res.status === 401 && isBrowser()) {
    let destination = '/signin';
    if (window.location.pathname !== '/') {
      destination += `?redirectTo=${window.location.pathname}`;
    }
    window.location.assign(destination);
  }

  if (res.status < 200 && 299 < res.status && DEFAULTS.throwOnError) {
    throw new Error(`An error occurred with the request: ${res.statusText}`);
  }

  return res.data;
};

/**
 * Mutate data on the API.
 * Requires the client to be initialized.
 *
 * @param url
 * @param options
 */
export const mutateInternal = async <RouteName>(
  url: string,
  options: {
    method?: AxiosRequestConfig['method'];
    payload?: Record<string, unknown> | Groupthink.RequestPayload<RouteName>;
  }
) => {
  const { method = 'POST', payload } = options;

  try {
    await axios(url, {
      method,
      data: payload,
    });
  } catch (error) {
    if (DEFAULTS.throwOnError) {
      throw error;
    }
  }
};

/**
 * Make an API request.
 * Requires the client to be initialized.
 *
 * @param url
 * @param mutate
 * @param method
 * @param options
 * @returns AxiosResponse
 */
export const apiRequest = async <RouteName = void>(
  url: string,
  mutate?: SWRResponse['mutate'] | null,
  method?: AxiosRequestConfig['method'],
  options?: {
    setErrors?: Groupthink.ErrorsCallback;
    setLoading?: (loading: boolean) => void;
    payload?: Record<string, unknown> | Groupthink.RequestPayload<RouteName> | FormData;
    headers?: Record<string, string>;
    onSuccess?: Groupthink.OnSuccessCallback | Groupthink.OnSuccessResponseDataCallback<RouteName>;
  }
): Promise<AxiosResponse | null> => {
  const { setErrors, setLoading, payload, onSuccess, headers } = options || {};

  setErrors?.({});
  setLoading?.(true);
  return axios(url, {
    method,
    data: payload,
    headers,
  })
    .then((res) => {
      if (mutate) {
        mutate().then(() => {
          setLoading?.(false);
          onSuccess?.(res.data?.data ?? res.data);
        });
      } else {
        setLoading?.(false);
        onSuccess?.(res.data?.data ?? res.data);
      }
      return res;
    })
    .catch((error) => {
      console.error(`[apiRequest] ${error}`, { error, url, options });
      // TODO: We should be able to determine which errors are expected to be returned by a RouteName
      setErrors?.(error.response?.data.errors ?? { error });
      setLoading?.(false);
      return null;
    });
};

/**
 * Configure the axios instance to use an Authorization header.
 */
export const configureAxiosBaseUrl = (url: string) => {
  axios.defaults.baseURL = url;
};

/**
 * Configure the axios instance to use an Authorization header.
 */
export const configureAuthorizationHeader = (token: string) => {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
};

/**
 * Configure the axios instance to use credentials.
 */
export const configureWithCredentials = (useCredentials: boolean) => {
  axios.defaults.withCredentials = useCredentials;
};

/**
 * Clears the Authorization header from the axios instance.
 */
export const clearAuthorizationHeader = () => {
  delete axios.defaults.headers.Authorization;
};
