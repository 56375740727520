import useSWR from 'swr';
import { apiRequest, fetcher } from '../lib';

export const useActions = (workspaceId: string, status: string = 'draft') => {
  const url = workspaceId ? `/v1/workspaces/${workspaceId}/actions?status=${status}` : null;

  const {
    data: action,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'action.index'>>(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    actions: action?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useAction = (workspaceId: string, actionId: string) => {
  const url = workspaceId && actionId ? `/v1/workspaces/${workspaceId}/actions/${actionId}` : null;

  const {
    data: action,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'action.show'>>(() => url, fetcher, {
    keepPreviousData: true,
  });

  const updateActionProperties = async ({ setErrors, setIsUpdating, onSuccess, payload }) => {
    // @ts-ignore
    apiRequest(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const acceptAction = async ({ setErrors, setIsUpdating, onSuccess, payload }) => {
    apiRequest(url + '/accept', mutate, 'POST', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const rejectAction = async ({ setErrors, setIsUpdating, onSuccess, payload }) => {
    apiRequest(url + '/reject', mutate, 'POST', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  return {
    // @ts-ignore
    action: action?.data,
    updateActionProperties: updateActionProperties,
    acceptAction,
    rejectAction,
    isLoading,
    isError: error,
    mutate,
  };
};
