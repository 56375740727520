/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';
import useSWR from 'swr';
import { useUser } from './user';
import _ from 'underscore';

enum DocumentUserRole {
  Owner = 'owner',
  Editor = 'editor',
  Viewer = 'viewer',
}

export const documentUserRoles = [
  DocumentUserRole.Owner,
  DocumentUserRole.Editor,
  DocumentUserRole.Viewer,
] as Groupthink.DocumentUserRole[];

export const useDocument = (id?: string) => {
  const url = `/v1/documents/${id}`;
  const {
    data: document,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'document.show'>>(
    () => (id ? `${url}` : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const updateDocument = async <RouteName = 'document.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const pinDocument = async <RouteName = 'document.pin'>(
    userId: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) => {
    const pinUrl = `/v1/documents/${id}/users/${userId}/pinned`;
    apiRequest<RouteName>(pinUrl, mutate, 'POST', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const unpinDocument = async <RouteName = 'document.deletePin'>(
    userId: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) => {
    const pinUrl = `/v1/documents/${id}/users/${userId}/pinned`;
    apiRequest<RouteName>(pinUrl, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const inviteUser = async <RouteName = 'document.addUser'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) => {
    const inviteUrl = `/v1/documents/${id}/users`;
    apiRequest<RouteName>(inviteUrl, mutate, 'POST', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const updateUser = async <RouteName = 'document.updateUser'>(
    id: string,
    userId: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/documents/${id}/users/${userId}`, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const removeUser = async <RouteName = 'document.removeUser'>(
    userId: string,
    {
      setErrors,
      setIsRemoving,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsRemoving?: (isRemoving: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(`/v1/documents/${id}/users/${userId}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsRemoving,
      onSuccess,
    });

  const deleteDocument = async <RouteName = 'document.delete'>({
    setErrors,
    setIsDeleting,
    onSuccess,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  const { user } = useUser('me');

  let isCurrentUserDocumentEditor = false;
  let isCurrentUserDocumentOwner = false;
  let owner;

  if (user && document?.data?.visibility === 'workspace') {
    isCurrentUserDocumentOwner = true;
    isCurrentUserDocumentEditor = true;
  } else if (document?.data?.visibility === 'public') {
    isCurrentUserDocumentOwner = false;
    isCurrentUserDocumentEditor = true;
  } else if (user && document?.data && document?.data?.users) {
    owner = document?.data?.users?.find((u) => u.role === DocumentUserRole.Owner);
    // is_editor is only available on UserResource
    // The agenda.show endpoint can return an AgendaResource or a BriefAgendaResource, but only AgendaResource will have a UserResource.
    const currentUser = _.indexBy(document.data.users, 'id')[user.id] as Groupthink.UserResource;
    isCurrentUserDocumentOwner = currentUser?.id === owner?.id;
    isCurrentUserDocumentEditor =
      currentUser?.id === owner?.id || currentUser?.role === DocumentUserRole.Editor;
  }

  return {
    document: document?.data,
    isLoading,
    isError: error,
    mutate,
    updateDocument,
    pinDocument,
    unpinDocument,
    deleteDocument,
    inviteUser,
    updateUser,
    removeUser,
    isCurrentUserDocumentEditor,
    isCurrentUserDocumentOwner,
  };
};

export const useDocuments = (workspaceId: string, folder_id: string) => {
  const url =
    workspaceId && folder_id
      ? `/v1/workspaces/${workspaceId}/folders/${folder_id}/documents`
      : null;

  const {
    data: document,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'document.index'>>(() => url, fetcher, {
    keepPreviousData: true,
  });

  const createDocument = async <RouteName = 'document.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });
  };

  return {
    // @ts-ignore
    documents: document?.data,
    isLoading,
    isError: error,
    mutate,
    createDocument,
  };
};

export const useUserDocuments = (workspaceId: string) => {
  const url = workspaceId ? `/v1/workspaces/${workspaceId}/documents` : null;

  const {
    data: document,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'document.index'>>(() => url, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    documents: document?.data,
    isLoading,
    isError: error,
    mutate,
  };
};
